<template>
  <div style="width: calc(100% - 200px);padding: 3%">
    <el-card class="box-card" style="width: 900px">
      <div slot="header" class="clearfix">
        <span>告示板</span>
      </div>
      <!--   告示板   -->
      <el-table
          :data="rulelist"
          style="width: 100%;"
          :show-header = false>
        <el-table-column
            prop="rules">
        </el-table-column>
      </el-table>
    </el-card>
    <el-card class="box-card" style="width: 900px">
      <el-select v-model="value" placeholder="请选择时间">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <el-select v-model="process" placeholder="请选择" v-if="processList.length != 0">
        <el-option
            v-for="item in processList"
            :key="item"
            :label="item"
            :value="item">
        </el-option>
      </el-select>
      <el-select v-model="selectTap" placeholder="请选择">
        <el-option
            v-for="item in selectTapList"
            :key="item"
            :label="item"
            :value="item">
        </el-option>
      </el-select>
      <el-select v-model="selectTime" placeholder="请选择">
        <el-option
            v-for="item in selectTimeList"
            :key="item"
            :label="item"
            :value="item">
        </el-option>
      </el-select>
      <el-button @click="PersonalStatisticsSelect">查询</el-button>
      <el-card class="box-card" style="width: 850px" v-if="processList.length !=0">
        <div slot="header" class="clearfix">
          <span>个人工作详情</span>
        </div>
        <!--   个人详情   -->
        <div>
          <span>自废数: {{personForm.feijiannum}}</span>
          <el-divider direction="vertical"></el-divider>
          <span>自废率: {{personForm.feijianper}}‰</span>
<!--          <el-divider direction="vertical"></el-divider>-->
<!--          <span>料废数: {{personForm.liaofeinum}}</span>-->
<!--          <el-divider direction="vertical"></el-divider>-->
<!--          <span>料废率: {{personForm.liaofeiper}}‰</span>-->
          <el-divider direction="vertical"></el-divider>
          <span>加工数量: {{personForm.worknum}}</span>
        </div>
      </el-card>

      <el-card class="box-card" style="width: 850px" v-if="personList.length != 1 && personList.length != 0">
        <div slot="header" class="clearfix">
          <span>工作总览</span>
        </div>
        <el-table
            :data="personListTittle"
            :show-header="false"
            style="width: 800px">
          <el-table-column
              prop="process"
              width="80">
          </el-table-column>
          <el-table-column
              prop="personList">
            <template slot-scope="scope">
              <el-table
                  :data="scope.row.personList"
                  :show-header="false"
                  style="width: 720px">
                <el-table-column
                    prop="name"
                    width="140">
                </el-table-column>
                <el-table-column
                    prop="id"
                    v-if="false"
                    width="140">
                </el-table-column>
                <el-table-column
                    prop="worknum"
                    width="140">
                </el-table-column>
                <el-table-column
                    prop="feijiannum"
                    width="140">
                </el-table-column>
                <el-table-column
                    prop="feijianper"
                    width="160">
                </el-table-column>
<!--                <el-table-column-->
<!--                    prop="liaofeinum"-->
<!--                    width="80">-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                    prop="liaofeiper"-->
<!--                    width="100">-->
<!--                </el-table-column>-->
              </el-table>
            </template>
          </el-table-column>
        </el-table>
        <el-table
            :data="personList"
            :show-header="false"
            style="width: 820px"
            height="650">
          <el-table-column
              prop="process"
              width="80">
          </el-table-column>
          <el-table-column
              prop="personList">
            <template slot-scope="scope">
              <el-table
                  :data="scope.row.personList"
                  :show-header="false"
                  style="width: 720px">
                <el-table-column
                    prop="name"
                    width="140">
                </el-table-column>
                <el-table-column
                    prop="id"
                    v-if="false"
                    width="140">
                </el-table-column>
                <el-table-column
                    prop="worknum"
                    width="140">
                </el-table-column>
                <el-table-column
                    prop="feijiannum"
                    width="140">
                </el-table-column>
                <el-table-column
                    prop="feijianper"
                    width="160">
                </el-table-column>
<!--                <el-table-column-->
<!--                    prop="liaofeinum"-->
<!--                    width="120">-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                    prop="liaofeiper"-->
<!--                    width="140">-->
<!--                </el-table-column>-->
                <el-table-column type="expand">
                  <template slot-scope="props">
                    <el-table
                        :data="props.row.tapList"
                        :show-header="false"
                        v-if="props.row.tapList !== null && props.row.tapList !=='' && props.row.tapList !=='undefined'"
                        style="width: 620px">
                      <el-table-column
                          prop="tap"
                          width="120">
                      </el-table-column>
                      <el-table-column
                          prop="worknum"
                          width="120">
                      </el-table-column>
                      <el-table-column
                          prop="feijiannum"
                          width="120">
                      </el-table-column>
                      <el-table-column
                          prop="feijianper"
                          width="140">
                      </el-table-column>
<!--                      <el-table-column-->
<!--                          prop="liaofeinum"-->
<!--                          width="80">-->
<!--                      </el-table-column>-->
<!--                      <el-table-column-->
<!--                          prop="liaofeiper"-->
<!--                          width="100">-->
<!--                      </el-table-column>-->
                    </el-table>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-card>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "Home",
  data() {
    return {
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      rulelist: [],
      processList: [],
      selectTapList: ['加工数','入库数'], //'加工数','已入库工件加工数','入库数'
      selectTimeList: ['排产时间','入库时间'],
      selectTap: '加工数',
      selectTime: '入库时间',
      process: "",
      options: [{
        value: '本年',
        label: '本年'
      }, {
        value: '去年',
        label: '去年'
      }, {
        value: '6个月内',
        label: '6个月内'
      }, {
        value: '本季度',
        label: '本季度'
      }, {
        value: '上季度',
        label: '上季度'
      }, {
        value: '本月',
        label: '本月'
      }, {
        value: '上个月',
        label: '上个月'
      }, {
        value: '本周',
        label: '本周'
      }, {
        value: '上周',
        label: '上周'
      }],
      value: '本月',
      personForm:{},
      personList:[],
      personListTittle:[{
        process: "工序",
        personList: [{
          feijiannum: '自废件数',
          feijianper: '自废率( ‰)',
          id: 'id',
          liaofeinum: '料废件数',
          liaofeiper: '料废率( ‰)',
          name: "姓名",
          worknum: "加工数"
        }]
      }],
    }
  },
  //加载表格
  mounted() {
    this.select()
    // this.getTongzhi()
  },
  methods: {
    getTongzhi(){
      request.post("/getTongZhi").then(res1 =>{
        if (res1.code == "233"){
          this.$notify({
            message: res1.msg,
            type: 'success'
          });
        } else if (res1.code == "200"){
          const h = this.$createElement;

          this.$notify({
            title: '通知',
            message: h('i', { style: 'color: teal'}, res1.data)
          });

        }else {
          this.$notify.error({
            title: '错误',
            message: res.code + ":" + res.msg
          });
        }
      })
    },
    select(){
      this.ManagementSystemSelect();
      request.post("/getPr",this.user.id).then(res1 =>{
        this.processList = res1.data
        this.process = res1.data[0]
        this.PersonalStatisticsSelect();
      })
    },
    ManagementSystemSelect(){
      request.post("/ManagementSystem").then(res =>{
        this.rulelist = []
        for (let i = 0; i < res.data.rules.length; i++) {
          this.rulelist.push({rules: res.data.rules[i]});
        }
      })
    },
    PersonalStatisticsSelect(){
      this.personList = [{
        process: "工序",
        personList: [{
          feijiannum: '自废件数',
          feijianper: '自废率( ‰)',
          id: 'id',
          liaofeinum: '料废件数',
          liaofeiper: '料废率( ‰)',
          name: "姓名",
          worknum: "加工数"
        }]
      }]
      let PersonalStatisticsReq = {
        time: this.value,
        id: this.user.id,
        pro: this.process
      }
      if (this.selectTime == '排产时间'){
        if (this.selectTap == '加工数'){
          request.post("/PersonalStatistics",PersonalStatisticsReq).then(res1 =>{
            this.personForm = res1.data
          })
          request.post("/PersonalListStatistics",PersonalStatisticsReq).then(res1 =>{
            this.personList = res1.data
            //this.personList.unshift(item)
          })
        } else if (this.selectTap == '已入库工件加工数'){
          request.post("/PersonalStatisticsFinishedWorked",PersonalStatisticsReq).then(res1 =>{
            this.personForm = res1.data
          })
          request.post("/PersonalListStatisticsFinishedWorked",PersonalStatisticsReq).then(res1 =>{
            this.personList = res1.data
            //this.personList.unshift(item)
          })
        } else if (this.selectTap == '入库数'){
          request.post("/PersonalStatisticsFinished",PersonalStatisticsReq).then(res1 =>{
            this.personForm = res1.data
          })
          request.post("/PersonalListStatisticsFinished",PersonalStatisticsReq).then(res1 =>{
            this.personList = res1.data
            //this.personList.unshift(item)
          })
        } else {
          this.$notify.error({
            title: '错误',
            message: '请选择查询标识'
          });
        }
      } else if (this.selectTime == '入库时间'){
        if (this.selectTap == '加工数'){
          request.post("/PersonalStatisticsOutput",PersonalStatisticsReq).then(res1 =>{
            this.personForm = res1.data
          })
          request.post("/PersonalListStatisticsOutput",PersonalStatisticsReq).then(res1 =>{
            this.personList = res1.data
            //this.personList.unshift(item)
          })
        } else if (this.selectTap == '已入库工件加工数'){
          request.post("/PersonalStatisticsFinishedWorkedOutput",PersonalStatisticsReq).then(res1 =>{
            this.personForm = res1.data
          })
          request.post("/PersonalListStatisticsFinishedWorkedOutput",PersonalStatisticsReq).then(res1 =>{
            this.personList = res1.data
            //this.personList.unshift(item)
          })
        } else if (this.selectTap == '入库数'){
          request.post("/PersonalStatisticsFinishedOutput",PersonalStatisticsReq).then(res1 =>{
            this.personForm = res1.data
          })
          request.post("/PersonalListStatisticsFinishedOutput",PersonalStatisticsReq).then(res1 =>{
            this.personList = res1.data
            //this.personList.unshift(item)
          })
        } else {
          this.$notify.error({
            title: '错误',
            message: '请选择查询标识'
          });
        }
      }
    },
    childrenTableClass(){
      return "children"
    }
  }
}
</script>

<style scoped>

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}

.box-card {
  width: 480px;
}

.el-table .children {
  background: oldlace;
}
</style>
